define('a2s-test/api/update/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    messages: Ember.A(),
    clear() {
      this.get('messages').clear();
    },
    actions: {
      update(id, precio) {
        this.get('messages').clear();
        this.set("application.textLog", "UPDATE frutas");
        this.get('application').req('PUT', `col/frutas/${id}`, {}, { fruta: { "precio": precio } }).then(resp => {
          if (resp.frutas) {
            this.get('messages').pushObject({ type: "success", msg: `Fruta: ${resp.frutas[0].nombre} actualizada correctamente` });
          } else {
            this.get('messages').pushObject({ type: "warning", msg: `Problemas al actualizar` });
          }
        }).catch(() => {
          this.get('messages').pushObject({ type: "danger", msg: `Problema Inesperado` });
        });
      }
    }

  });
});