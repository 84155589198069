define('a2s-test/api/create/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {},
    setupController: function (controller, model) {
      this._super(controller, model);
      controller.clear();
    }
  });
});