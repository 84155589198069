define('a2s-test/email/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    clear() {
      this.set('resMessage', null);
      this.set('errMessage', null);

      this.set('to', "");
      this.set('subject', "");
      this.set('body', "");
    },
    actions: {
      submit(form) {
        this.set('resMessage', null);
        this.set('errMessage', null);
        let self = this;
        this.set("application.textLog", "Enviar Email");
        let numero = '34' + form.get('number');
        this.get('application').req('POST', 'col/emails/send', {}, {
          "to": form.to,
          "subject": form.subject,
          "text": form.body
        }).then(resp => {
          if (resp.resp.accepted.length) {
            self.set('resMessage', `Enviado a: ${resp.resp.accepted}`);
            self.set('errMessage', null);
          } else {
            self.set('resMessage', null);
            self.set('errMessage', "No enviado");
          }
        }).catch(err => {
          self.set('resMessage', null);
          self.set('errMessage', "Error inesperado!, contacte al administrador");
        });
      }
    }
  });
});