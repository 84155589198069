define('a2s-test/application/controller', ['exports', 'fetch'], function (exports, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    login: false,
    email: "",
    password: "",
    error: false,
    errMessage: "Ha introducido incorrectamente el email o la clave.",
    actions: {
      submit() {
        if (true || this.get('email') == "tester2@a2system.net" && this.get('password') === "t3st3r4cc0unt") {

          (0, _fetch.default)('https://a2server.a2system.net:4541/api/v1/col/users/login', {
            credentials: 'include',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "user": {
                "email": {
                  "login": 'test99@mail.com',
                  "password": 'patataman'
                }
              },
              "type": "mail"
            })
          }).then(response => {
            this.set('error', false);
            this.set('login', true);
            this.sendAction('login');
            // console.log("bien");
            // console.log(response.json());
            // return response.json();
          }).catch(err => {
            this.set('errMessage', "Problemas con el servidor intente más tarde o comuníquese con el administrador");
            this.set('error', true);
          });
        } else {
          this.set('error', true);
        }
        // return fetch('https://a2server.a2system.net:4541/api/v1/col/users/login',{
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify({
        //     "user": {
        //         "email":{
        //           "login": this.get('email'),
        //           "password": this.get('password'),
        //         }
        //     },
        //     "type":"mail"
        //   })        
        // }).then(response =>{
        //   this.set('login', true);
        //   this.sendAction('login');
        //   // console.log("bien");
        //   // console.log(response.json());
        //   // return response.json();
        // }).catch(err=>{
        //   console.log(err);
        // });
      }
    }
  });
});