define('a2s-test/pubsub/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    logData: "",
    model: "",
    messages: Ember.A(),
    clear() {
      this.get('messages').clear();
    },
    actions: {
      onChange() {
        this.get('messages').clear();
      },
      subscribe() /*topic*/{
        this.get('messages').clear();
        this.set("application.textLog", "SUBSCRIBE");
        this.get('application').req('PUT', 'srv/pubsub', {}, { "topic": "series" }).then(resp => {
          if (resp.resp === "ok") {
            this.get('messages').pushObject({ type: "success", msg: `Se ha suscrito correctamente al tema "series"` });
          } else {
            this.get('messages').pushObject({ type: "warning", msg: `Error: ${resp.message}` });
          }
        });
        // .then(data=>{
        //   console.log(data);
        // })
        // .catch(err=>{
        //   console.log(err);
        // })

        //PUT https://a2server.a2system.net:4541/api/v1/srv/pubsub
      },
      publish(message) {
        this.get('messages').clear();
        this.set("application.textLog", "PUBLISH");
        this.get('application').req('POST', 'srv/pubsub', {}, { "topic": "series", "message": message }).then(resp => {
          if (resp.resp === "ok") {
            this.get('messages').pushObject({ type: "success", msg: `Mensaje publicado correctamente: ${resp.resp}` });
          } else {
            this.get('messages').pushObject({ type: "warning", msg: `Error: ${resp.message}` });
          }
        });
      },
      receive() {
        this.get('messages').clear();
        this.set("application.textLog", "RECEIVE");
        this.get('application').req('GET', 'srv/pubsub', {}).then(resp => {
          console.log(resp);
          if (resp.messages.length === 0) {
            this.get('messages').pushObject({ type: "success", msg: `No hay mensajes nuevos en este canal. Publica algún mensaje primero (en el Paso 2)` });
          } else {
            this.get('messages').pushObject({ type: "success", msg: `${resp.messages.length} mensajes recibidos.` });
          }
          this.set('model', resp.messages);
        });
        //GET "https://a2server.a2system.net:4541/api/v1/srv/pubsub"
      }

    }
  });
});