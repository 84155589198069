define('a2s-test/sms/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    clear() {
      this.set('resMessage', null);
      this.set('errMessage', null);

      this.set('number', "");
      this.set('message', "");
    },
    actions: {
      sendSMS(form) {
        this.set('resMessage', null);
        this.set('errMessage', null);
        let self = this;
        this.set("application.textLog", "Enviar SMS");
        let numero = '34' + form.get('number');
        this.get('application').req('POST', 'srv/sms/send', {}, {
          "to": numero,
          "msg": form.message
        }).then(resp => {
          if (resp.status) {
            self.set('resMessage', resp.msg);
            self.set('errMessage', null);
          } else {
            self.set('resMessage', null);
            self.set('errMessage', resp.message);
          }
        }).catch(err => {
          self.set('resMessage', null);
          self.set('errMessage', "Error inesperado!, contacte al administrador");
        });
      }
    }
  });
});