define('a2s-test/push/controller', ['exports', 'fetch'], function (exports, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    email: "tester2@a2system.net", //"javierarpa@gmail.com",
    title: "Prueba Push",
    message: "Mensaje de Push. Todo bien! 👍",
    messages: Ember.A(),
    srv: "https://service.tourme.com/api/v1/",
    clear() {
      this.get('messages').clear();
    },
    actions: {
      push(form) {
        let email = form.email.toLowerCase();
        let title = form.title;
        let message = form.message;
        let app = this.get('application');

        this.get('messages').clear();
        app.setLog("PUSH Prueba");
        let url = `${this.get('srv')}col/users?email.login=${email}&fields=_id`;
        app.addLog(`<br><br><strong>->TX:</strong> GET ${url}`);
        (0, _fetch.default)(url).then(resp => resp.json()).then(resp => {
          app.addLog(`<br><br><strong><-RX:</strong> ${JSON.stringify(resp, null, ' ')}`);
          if (resp.users[0]) {
            let id = resp.users[0]._id;
            let url = `${this.get('srv')}col/devices?uid=${id}&sort=-lastLogin&limit=1`;
            app.addLog(`<br><br><strong>->TX:</strong> GET ${url}`);
            return (0, _fetch.default)(url).then(resp => resp.json());
          } else {
            this.get('messages').pushObject({ type: "danger", msg: `Usuario no registrado en la aplicación. Por favor realiza el Paso 1 o verifica la dirección de correo.` });
          }
        }).then(resp => {
          if (resp) {
            app.addLog(`<br><br><strong><-RX:</strong> ${JSON.stringify(resp, null, ' ')}`);
            if (resp.devices && resp.devices.length) {
              let token = resp.devices[0].push_token;
              let platform = resp.devices[0].platform === 'android' ? 'google' : resp.devices[0].platform;
              let client = resp.devices[0].appName;
              let url = `${this.get('srv')}srv/push/send?service=${platform}&platform=${resp.devices[0].platform}&client=${client}`;
              let bb = {
                "to": token,
                "data": {
                  "alert": {
                    "title": title,
                    "body": message
                  },
                  "payload": {},
                  "certId": "production"
                }
              };
              app.addLog(`<br><br><strong>->TX:</strong> POST ${url} <br> ${JSON.stringify(bb, null, ' ')}`);

              (0, _fetch.default)(url, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(bb)
              }).then(resp => resp.json()).then(resp => {
                app.addLog(`<br><br><strong><-RX:</strong> ${JSON.stringify(resp, null, ' ')}`);
                if (!resp.error) {
                  this.get('messages').pushObject({ type: "success", msg: `¡Bien! Push Enviado` });
                } else {
                  this.get('messages').pushObject({ type: "warning", msg: `Error: ${resp.resp.error}` });
                }
              });
            } else {
              this.get('messages').pushObject({ type: "danger", msg: `Este Número no tiene dispositivo asociado` });
            }
          }
        }).catch(() => {
          this.get('messages').pushObject({ type: "danger", msg: `Problema Inesperado` });
        });
      }
    }
  });
});