define('a2s-test/usuarios/facebook/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    hasFacebook: false,
    userId: "",
    accessToken: "",
    torii: Ember.inject.service('torii'),
    messages: Ember.A(),
    clear() {
      this.get('messages').clear();
    },
    loginFb(auth) {
      console.log('FaceBook Auth: ' + auth.userId);
      console.log(auth.accessToken);
      this.get("application").addLog(`<br>AUTH a A2Server con Token de Facebook`);

      this.get('application').req('POST', 'col/users/login', {}, {
        "user": {
          "fb": {
            "id": auth.userId,
            "token": auth.accessToken
          }
        },
        "type": "fb"
      }).then(resp => {
        console.log(resp);
        if (resp.user) {
          this.get('messages').pushObject({ type: "success", msg: `${resp.user.name} Hizo Login en A2Server correctamente` });
        } else {
          this.get('messages').pushObject({ type: "warning", msg: `Problemas al hacer login` });
        }
      }).catch(err => {
        console.log(err);
        this.get('messages').pushObject({ type: "danger", msg: `Problema Inesperado` });
      });
    },
    actions: {
      signInToComment() {

        //var controller = this.controllerFor('post');
        // The provider name is passed to `open`
        this.get("application").setLog("AUTH Facebook");
        this.get('torii').open('facebook-connect').then(authorization => {
          this.get("application").addLog(`<br><br><strong><-RX:</strong>${JSON.stringify(authorization, null, ' ')}`);

          if (authorization.accessToken) {
            this.set('accessToken', authorization.accessToken);
            this.set('userId', authorization.userId);
            this.set('hasFacebook', true);
            this.get('messages').pushObject({ type: "success", msg: `Autenticado correctamente con Facebook` });
            this.loginFb(authorization);
          } else {
            this.get('messages').pushObject({ type: "warning", msg: `NO SE HA Autenticado correctamente con Facebook` });
          }
        }).catch(err => {
          this.get('messages').pushObject({ type: "danger", msg: `¡Problemas al hacer Login! ${err}` });
          console.log("mal", err);
        });
        //this.send('signInToCommentRoute');
      }
    }
  });
});