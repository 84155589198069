define('a2s-test/estadistica/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    items1: [{
      src: 'assets/images/15A.png',
      w: '886',
      h: '366',
      pid: 'image-A',
      txt: '&bullet; Desde el menú lateral, seleccionar "Aplicaciones" -> "Ver" (DemoApp)<br>&nbsp;'
    }, {
      src: 'assets/images/15B.png',
      w: '1560',
      h: '923',
      pid: 'image-B',
      iB: true,
      txt: '&bullet; Por defecto, al crear una aplicación se pueden ver 3 secciones con gráficas útiles para el desarrollador.<br>Se incluye una sección de usuarios registrados por tipo de cuenta (email, número de teléfono, nombre de usuario, etc.), y también por tipo de cliente (dispositivo Android, dispositivo iOS, o cliente Web).<br>&nbsp;'
    }, {
      src: 'assets/images/15C.png',
      w: '1467',
      h: '843',
      pid: 'image-C',
      iB: true,
      txt: '&bullet; Otra sección de estadísticas de envío de notificaciones push, separada por dispositivos móviles (Android, iOS), y de otros grupos (incluyendo notificaciones a navegadore web). Se pueden ver también contablización de errores en los envíos, si los hubiera.<br>&nbsp;'
    }, {
      src: 'assets/images/15D.png',
      w: '1253',
      h: '493',
      pid: 'image-D',
      iB: true,
      txt: '&bullet; Adicionalmente, reportes de uso de conexiones realizadas por cliente.<br>&nbsp;'
    }],
    items2: [{
      src: 'assets/images/15E.png',
      w: '585',
      h: '298',
      pid: 'image-E',
      txt: '&bullet; Desde el "Panel de Control", entrar en "Administrar Gráficas" -> "Crear Nueva Sección".<br>&nbsp;'
    }, {
      src: 'assets/images/15F.png',
      w: '968',
      h: '351',
      pid: 'image-F',
      iB: true
    }, {
      src: 'assets/images/15G.png',
      w: '603',
      h: '245',
      pid: 'image-G',
      txt: '&bullet; Agregar el nombre que se desea para dicha sección que contendrá las gráficas nuevas.<br>&nbsp;'
    }, {
      src: 'assets/images/15H.png',
      w: '949',
      h: '299',
      pid: 'image-H',
      iB: true,
      txt: '&bullet; Se debe "Activar" la sección nueva, y luego "Editar" para entrar a ver sus gráficas asociadas.  Hacer click en "Crear nueva Gráfica".<br>&nbsp;'
    }, {
      src: 'assets/images/15I.png',
      w: '969',
      h: '431',
      pid: 'image-I'
    }, {
      src: 'assets/images/15J.png',
      w: '595',
      h: '725',
      pid: 'image-J',
      iB: true,
      txt: `&bullet; Rellenar los datos requeridos para la nueva gráfica<br>
      <ul>
      <li>El nombre de la gráfica para mostrar en el "Panel de Control".</li>
      <li>La ruta REST API de donde se obtendrán los datos. Para este ejemplo, se puede usar la ruta "app/charts/users" para mostrar una gráfica de usuarios. Un desarrollador podría crear su propia ruta y proveer de datos personalizados para sus gráficas.</li>
      <li>Hacer click en el botón "Probar" para saber si la ruta ha sido bien configurada.</li>
      <li>Seleccionar el tipo de gráfica que se quiere mostrar. Puede ser de barras verticales, horizontales, de líneas, radiales tipo radar o donut, etc.</li>
      <li>Seleccionar el tamaño que se quiere que ocupe la gráfica en el ancho de la pantalla.</li>
      <li>Confirmar la creación de la gráfica.</li>
      </ul>
      <br>&nbsp;
      `
    }, {
      src: 'assets/images/15K.png',
      w: '804',
      h: '156',
      pid: 'image-K',
      txt: '&bullet; Luego, en el "Panel de Control", se puede ver la nueva sección que ha sido creada, y las gráficas que se le han agregado.<br>&nbsp;'
    }, {
      src: 'assets/images/15L.png',
      w: '764',
      h: '420',
      pid: 'image-L',
      iB: true
    }],
    actions: {
      onChange() {
        window.scrollTo(0, 0);
      }
    }
  });
});