define('a2s-test/router', ['exports', 'a2s-test/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('usuarios', function () {
      this.route('correo');
      this.route('telefono');
      this.route('facebook');
      this.route('docs');
    });
    this.route('seguridad', function () {
      this.route('acl');
      this.route('restringido');
    });
    this.route('almacenamiento', function () {
      this.route('validacion-ok');
      this.route('validacion-fail');
      this.route('hook-pre');
      this.route('hook-post');
      this.route('docs');
    });
    this.route('geolocalizacion', function () {
      this.route('radio');
      this.route('geopuntos');
      this.route('geocercas');
      this.route('docs');
    });
    this.route('pupsub');
    this.route('pubsub');

    this.route('usuario', function () {
      this.route('correo');
      this.route('telefono');
    });
    this.route('push');
    this.route('almacenamiento-archivos');
    this.route('almacenamiento-multimedia');
    this.route('media-streaming');
    this.route('social');
    this.route('sms');
    this.route('email');
    this.route('api', function () {
      this.route('create');
      this.route('read');
      this.route('update');
      this.route('delete');
      this.route('docs');
    });
    this.route('git');
    this.route('estadistica');
    this.route('server-side', function () {
      this.route('post');
      this.route('externo');
    });
  });

  exports.default = Router;
});