define('a2s-test/git/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    messages: Ember.A(),
    items: [{
      src: 'assets/images/14A.png',
      w: '949',
      h: '439',
      pid: 'image-A',
      txt: '&bullet; Desde el menú lateral, seleccionar "Aplicaciones" -> "Editar" (DemoApp)<br>&nbsp;'
    }, {
      src: 'assets/images/14B.png',
      w: '745',
      h: '550',
      pid: 'image-B',
      iB: true,
      txt: '&bullet; Pestaña "Repositorio"<br>&nbsp;'
    }, {
      src: 'assets/images/14C.png',
      w: '734',
      h: '357',
      pid: 'image-C',
      iB: true,
      txt: '&bullet; Seleccionar la rama "develop" en la sección de "Checkout"<br>&nbsp;'
    }, {
      src: 'assets/images/14D.png',
      w: '706',
      h: '348',
      pid: 'image-D',
      iB: true,
      txt: '&bullet; Solicitar el cambio de rama con el botón "Checkout" y luego confirmar<br>&nbsp;'
    }, {
      src: 'assets/images/14E.png',
      w: '606',
      h: '262',
      pid: 'image-E',
      iB: true

    }, {
      src: 'assets/images/14F.png',
      w: '952',
      h: '443',
      pid: 'image-F',
      iB: true,
      txt: '&bullet; <mark><strong>Para que el cambio surja efecto, se debe reiniciar la aplicación.</strong></mark><br>Desde el menú lateral, seleccionar "Aplicaciones" -> "Reiniciar" (DemoApp). Luego, confirmar el reinicio en "Reiniciar".<br>&nbsp;'
    }, {
      src: 'assets/images/14G.png',
      w: '606',
      h: '197',
      pid: 'image-G',
      iB: true

    }],
    actions: {
      git1() {
        this.get('messages').clear();
        this.get("application").setLog("PRUEBA GIT 1");
        this.get('application').req('GET', 'col/geos/git-test', {}).then(resp => {
          console.log(resp);
          if (resp.resp) {
            this.get('messages').pushObject({ type: "success", msg: `¡Bien! ${JSON.stringify(resp.resp, null, 2)}` });
          } else {
            this.get('messages').pushObject({ type: "warning", msg: `Problemas con la petición` });
          }
        }).catch(() => {
          this.get('messages').pushObject({ type: "danger", msg: `Problema Inesperado` });
        });
      }
    }
  });
});