define('a2s-test/seguridad/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    messages: Ember.A(),
    model: Ember.A(),
    items: [{
      src: 'assets/images/2A.png',
      w: '949',
      h: '439',
      pid: 'image-A',
      txt: '&bullet; Desde el menú lateral, seleccionar <strong>"Aplicaciones"</strong> -> <strong>"Editar"</strong> (DemoApp)<br>&nbsp;'
    }, {
      src: 'assets/images/2B.png',
      w: '1065',
      h: '559',
      pid: 'image-B',
      iB: true,
      txt: '&bullet; Seleccionar la pestaña <strong>"ACL"</strong> -> pestaña <strong>"Colecciones"</strong>.<br>&nbsp;'
    }, {
      src: 'assets/images/2C.png',
      w: '840',
      h: '897',
      pid: 'image-C',
      iB: true,
      txt: `&bullet; Bajar hasta encontrar la colección <strong>"emails"</strong> (cerca del fondo), seleccionarla, y desmarcar la casilla <strong>"authenticate"</strong>
           (que se refiere a lo que pueden hacer los usuarios autenticados).<br><mark><strong>Importante:</strong> Asegurarse de desmarcar 
           sólo la colección <strong>"emails"</strong> y no otra, pues se podría restringir el acceso al resto de las pruebas</mark><br>&nbsp;`
    }, {
      src: 'assets/images/2D.png',
      w: '642',
      h: '260',
      pid: 'image-D',
      iB: true,
      txt: '&bullet; La colección "emails" debe quedar desmarcada, así:<br>&nbsp;'
    }, {
      src: 'assets/images/2E.png',
      w: '840',
      h: '344',
      pid: 'image-E',
      iB: true,
      txt: '&bullet; Para finalizar, guardar los cambios:<br>&nbsp;'

    }],
    clear() {
      this.get('messages').clear();
    },
    actions: {
      onChange() {
        this.get('messages').clear();
        this.get('model').clear();
      },
      read() {
        this.clear();
        this.set("application.textLog", "GET emails");
        this.get('application').req('GET', 'col/emails', {}).then(resp => {
          if (resp.emails) {
            this.get('messages').pushObject({ type: "success", msg: `¡Bien! Emails Recibidos` });

            this.set("model", resp.emails);
          } else {
            this.get('messages').pushObject({ type: "warning", msg: `${resp.message}` });
          }
        }).catch(() => {
          this.get('messages').pushObject({ type: "danger", msg: `Problema Inesperado` });
        });
      }
    }
  });
});