define('a2s-test/server-side/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    messages: Ember.A(),
    items: [{
      src: 'assets/images/16A.png',
      w: '886',
      h: '366',
      pid: 'image-A',
      txt: '&bullet; Desde el menú lateral, seleccionar "Aplicaciones" -> "Ver" (DemoApp)<br>&nbsp;'
    }, {
      src: 'assets/images/16B.png',
      w: '924',
      h: '661',
      pid: 'image-B',
      iB: true,
      txt: '&bullet; Luego, "Colecciones" -> "Modelo" (vehiculos)<br>&nbsp;'
    }, {
      src: 'assets/images/16C.png',
      w: '710',
      h: '696',
      pid: 'image-C',
      iB: true,
      txt: `&bullet; Bajo la definición del modelo de base de datos se pueden ver 2 hooks sobre la colección "vehículos" de la base de datos:
      <ul>
       <li><strong>Hook Pre-Save</strong>: en este hook se modifica la propiedad "detalles" del documento, <strong>ANTES de que sea almacenado</strong> en la base de datos (tanto al crear el documento como al modificarlo).</li>
       <li><strong>Hook Post-Delete</strong> (remove): en este hook se envía un email para notificar la eliminación de un documento, <strong>DESPUÉS de que el documento ha sido eliminado</strong> exitosamente.</li>
      </ul>
      <br>&nbsp;
      `
    }],
    clear() {
      this.get('messages').clear();
    },
    actions: {
      probar(type, file) {
        let app = this.get('application');
        this.get('messages').clear();
        app.setLog("<strong>Prueba Ejecución de Código</strong>");

        this.get('application').req('POST', 'col/geos/exec', {}, {
          "type": type,
          "file": file
        }).then(resp => {
          if (resp.stdout.length) {
            this.get('messages').pushObject({ type: "success", msg: `Respuesta ${resp.stdout}` });
          } else {
            this.get('messages').pushObject({ type: "warning", msg: `${resp.stderr}` });
          }
        }).catch(() => {
          this.get('messages').pushObject({ type: "danger", msg: `Problema Inesperado` });
        });
      }

    }
  });
});