define('a2s-test/media-streaming/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    messages: Ember.A(),
    inVideo: "",
    idJob: "",
    android: true,
    ios: true,
    web: true,
    sd: true,
    hd: true,
    outputs: Ember.A(),
    clear() {
      this.get('messages').clear();
      this.set("inVideo", "https://d2qguwbxlx1sbt.cloudfront.net/TextInMotion-VideoSample-576p.mp4");
      this.get('outputs').clear();
    },
    actions: {
      onChange() {
        this.get('messages').clear();
      },
      transformar(form) {
        this.get('messages').clear();
        this.set("application.textLog", "Transformar Vídeo");

        let targets = [];
        if (form.android) targets.push('ANDROID');
        if (form.ios) targets.push('IOS');
        if (form.web) targets.push('WEB_MPEG_DASH');

        let qualities = [];
        if (form.sd) qualities.push('SD');
        if (form.hd) qualities.push('HD');
        if (form.fhd) qualities.push('FHD');
        if (form.uhd) qualities.push('UHD');

        this.get('application').req('POST', 'srv/multimedia/streaming', {}, {
          "input": {
            "service": "url",
            "path": this.get('inVideo')
          },
          "output": {
            "service": "aws",
            "container": "micontenedor",
            "pathPrefix": "test-streaming/prueba-video-" + Math.floor(Math.random() * 1000 + 1),
            "targets": targets,
            "qualities": qualities,
            "thumbnail": true
          }
        }).then(resp => {
          if (resp) {
            this.get('messages').pushObject({ type: "success", msg: `Proceso: ${resp.id} ${resp.status}` });
            this.set('idJob', resp.id);
            this.set("showJob", false);
          } else {
            this.get('messages').pushObject({ type: "warning", msg: `Problemas al procesar` });
          }
        }).catch(() => {
          this.get('messages').pushObject({ type: "danger", msg: `Problema Inesperado` });
        });
      },
      verificar() {
        this.get('messages').clear();
        this.set("showJob", false);
        this.set("application.textLog", "Verificar Video");

        //1539964340899-4rufk7
        if (this.inVideo.length > 0) {
          let url = `srv/multimedia/streaming/${this.get('idJob')}`;
          this.get('application').req('GET', url, {}).then(resp => {
            if (resp.status === "processing") {
              this.set("showJob", true);
              this.set("statJob", "warning");
              this.set("titleJob", "Procesando Job");
              this.set("jobMessage", "Aún se está procesando el vídeo. Espera unos segundos y haz click nuevamente en el botón <strong>Verificar</strong>");
            } else {
              this.set("showJob", true);
              this.set("statJob", "info");
              this.set("titleJob", "Job Procesado Correctamente");
              this.set("jobMessage", "La transformación fue un éxito. Ve al paso 3 para comprobar el resultado");
              let o = resp.outputs.map(ele => {
                ele.playlist = `https://s3.eu-central-1.amazonaws.com/micontenedor/${ele.playlist}`;
                return ele;
              });
              this.set("outputs", o);
            }
          }).catch(() => {
            this.get('messages').pushObject({ type: "danger", msg: `Problema Inesperado` });
          });
        }
      }
    }
  });
});