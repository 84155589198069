define('a2s-test/usuarios/facebook/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function (controller, model) {
      this._super(controller, model);
      controller.clear();
    },
    actions: {
      signInToCommentRoute: function () {
        var controller = this.controllerFor('usuarios.facebook');
        // The provider name is passed to `open`
        this.get('torii').open('facebook-connect').then(function (authorization) {
          // FB.api is now available. authorization contains the UID and
          // accessToken.
          controller.set('hasFacebook', true);
        });
      }
    }
  });
});