define('a2s-test/almacenamiento/hook-post/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      return this.get('application').req('GET', 'col/vehiculos', {});
    }
  });
});