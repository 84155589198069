define('a2s-test/api/read/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    messages: Ember.A(),
    clear() {
      this.get('messages').clear();
    },
    actions: {
      read() {
        this.set("application.textLog", "RECEIVE");
        this.get('application').req('GET', 'col/frutas', {}).then(resp => {
          this.set("model", resp.frutas);
        });
      }
    }
  });
});