define('a2s-test/application/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);
      // Implement your custom setup after
      // this.controllerFor('application').set('showingPhotos', true); 
      controller.send('submit');
    },

    actions: {
      login() {
        this.transitionTo('index');
      }
    }
  });
});