define('a2s-test/api/create/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    messages: Ember.A(),
    clear() {
      this.get('messages').clear();
    },
    actions: {
      create(form) {
        this.get('messages').clear();
        this.set("application.textLog", "Crear documento");
        this.get('application').req('POST', 'col/frutas', {}, {
          "fruta": {
            "nombre": form.nombre,
            "color": form.color,
            "precio": form.precio,
            "inventario": form.inventario
          }
        }).then(resp => {
          if (resp.frutas) {
            this.get('messages').pushObject({ type: "success", msg: `Fruta: ${resp.frutas[0].nombre} creada correctamente` });
          } else {
            this.get('messages').pushObject({ type: "warning", msg: `Problemas al crear` });
          }
        }).catch(() => {
          this.get('messages').pushObject({ type: "danger", msg: `Problema Inesperado` });
        });
      }
    }
  });
});