define('a2s-test/api/delete/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    messages: Ember.A(),
    clear() {
      this.get('messages').clear();
    },
    actions: {
      delete(row) {
        let self = this;
        this.get('messages').clear();
        this.set("application.textLog", "DELETE frutas");
        this.get('application').req('DELETE', `col/frutas/${row._id}`, {}, {}).then(resp => {
          if (resp.frutas) {
            self.get('messages').pushObject({ type: "success", msg: `Fruta: ${resp.frutas.nombre} eliminada correctamente` });
          } else {
            this.get('messages').pushObject({ type: "warning", msg: `Problemas al eliminar` });
          }
        }).catch(() => {
          this.get('messages').pushObject({ type: "danger", msg: `Error de Servidor` });
        });
        let model = this.get('model.frutas');
        model.removeObject(row);
      }
    }
  });
});