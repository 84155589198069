define('a2s-test/almacenamiento-multimedia/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    model: "",
    messages: Ember.A(),
    images: Ember.A(),
    msgModal: "",
    cortar: "squared",
    rotar: "90",
    t: true,
    s: true,
    m: true,
    title: " Prueba desde ToroBack",
    description: " Estoy subiendo un vídeo desde el servicio ToroBack (A2 Server) a una cuenta de pruebas.",
    clear() {
      this.get('messages').clear();
      this.clearModal();
    },
    clearModal() {
      this.set('openModal', false);
      this.set('msgModal', "");
    },
    showModal(msg) {
      this.set('openModal', true);
      this.set('msgModal', msg);
    },
    actions: {
      onChange() {
        this.get('messages').clear();
      },
      uploadVideo(form) {
        let self = this;
        this.get('messages').clear();
        let input = document.querySelector('input[name="videoFlickr"]');
        let file = input.files[0] || null;
        if (!file) {
          this.showModal("Debes seleccionar un archivo primero");
        } else if (file.size > 20 * 1024 * 1024) {
          this.showModal("¡El Archivo es muy grande!<br>Debe ser menor a 20 MB");
        } else {
          this.set("application.textLog", "Subir Vídeo a Flickr");
          var data = new FormData();
          data.append('fileUpload', input.files[0]);
          data.append('data.title', form.title);
          data.append('data.description', form.description);
          data.append('data.private', false);
          console.log("file", file);
          console.log("form.title", form.title);
          console.log("form.description", form.description);
          this.get('messages').pushObject({ type: "warning", msg: "Subiendo vídeo: por favor espera unos segundos..." });
          this.get('application').upload('srv/social/post?service=flickr', data).then(resp => {
            this.get('messages').clear();
            return resp;
          }).then(resp => {
            if (resp.id && resp.url) {
              this.get('messages').pushObject({ type: "success", msg: `Vídeo subido correctamente a Flickr<br>Puedes verlo aquí: <a target="_blank" href="${resp.url}">${resp.url}</a>` });
            } else {
              this.get('messages').pushObject({ type: "warning", msg: "Error: Archivo no subido" });
            }
          }).catch(() => {
            this.get('messages').pushObject({ type: "danger", msg: "Error inesperado" });
          });
        }
      },
      upload(ele) {
        //validar si no existen archivos
        //validar si el tamaño es muy grande
        let self = this;
        this.get('messages').clear();
        let input = document.querySelector('input[name="imageUpload"]');
        console.log(input);
        let file = input.files[0] || null;
        let image = {};
        let resize = [];
        if (this.get("rotar") != "0") image.rotate = this.get("rotar");
        if (this.get("cortar") != "0") image.crop = this.get("cortar");
        if (this.get('t')) resize.push('t');
        if (this.get('s')) resize.push('s');
        if (this.get('m')) resize.push('m');
        if (this.get('l')) resize.push('l');
        if (this.get('xl')) resize.push('xl');
        if (resize.length > 0) image.resize = resize;

        if (!file) {
          this.showModal("Debes seleccionar un archivo primero");
        } else if (file.size > 5 * 1024 * 1024) {
          this.showModal("¡El Archivo es muy grande! <br> Debe ser menor a 5 MB");
        } else {
          this.set("application.textLog", "Transformar Archivo multimedia");

          var data = new FormData();
          data.append('fileUpload', input.files[0]);
          data.append('path', `app-demo/mm/${input.files[0].name}`);
          let fileName = input.files[0].name;
          let fileSplit = fileName.split(".");
          this.get('application').upload('srv/storage/upload?service=gcloud&container=a2server-pruebas&public=true', data).then(resp => {
            if (resp.file.path) {
              let path = resp.file.path;
              let service = resp.file.service;
              let container = resp.file.container;
              //this.get('messages').pushObject({type:"success", msg: `Archivo ${resp.file.path} subido`});
              // {"file":{"path":"app-demo/mm/9A.png","service":"gcloud","container":"a2server-pruebas","public":true,"url":"https://www.googleapis.com/download/storage/v1/b/a2server-pruebas/o/app-demo%2Fmm%2F9A.png?generation=1540316467706324&alt=media"}}

              this.get('application').req('POST', 'srv/multimedia/editImage', {}, {
                "input": { // esto es la respuesta "file" del comando del upload
                  "path": path,
                  "service": service,
                  "container": container
                },
                "image": image,
                "output": {
                  "service": service,
                  "container": container,
                  "pathPrefix": `app-demo/mm/${fileSplit[0]}-`,
                  "public": true
                }
              }).then(resp => {
                this.get('messages').pushObject({ type: "success", msg: `Archivo subido y transformado` });

                this.set('images', resp.images);
              });
            } else {
              this.get('messages').pushObject({ type: "danger", msg: "Error: Archivo no subido" });
            }
          });
        }
      }
    }

  });
});