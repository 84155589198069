define('a2s-test/geolocalizacion/geopuntos/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    geoName: "repsol",

    clear() {
      this.set('resMessage', null);
      this.set('errMessage', null);

      this.set('geoName', "repsol");
      this.set('gasolineras', []);
    },
    actions: {
      submit(form) {
        let self = this;
        this.set("application.textLog", "BUSCAR GEOPUNTOS POR NOMBRE");
        this.set('gasolineras', []);
        let geoName = form.geoName.trim();
        this.get('application').req('GET', `col/gasolineras?limit=100&fields=loc,workingHours,name,localId,currency,prices&q={"name":{"$regex":"${geoName}", "$options": "i"}}`, {}).then(resp => {
          if (resp.errors && resp.errors[0].status == "422" && resp.errors[0].title && resp.errors[0].detail) {
            self.set('errMessage', `${resp.errors[0].title}:${resp.errors[0].detail}`);
          } else {
            let num = resp.gasolineras.length;
            self.set('resMessage', `${num} Gasolineras Encontradas`);
            self.set('gasolineras', resp.gasolineras);
            self.set('errMessage', null);
          }
        });
      }
    }
  });
});