define('a2s-test/geolocalizacion/geocercas/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    items: ["Sevilla", "Madrid"],
    resMessage: "",
    citySelected: "Sevilla",
    latitud: Ember.computed('citySelected', function () {
      return this.get('citySelected') === "Sevilla" ? 37.392554 : 40.434152;
    }),
    longitud: Ember.computed('citySelected', function () {
      return this.get('citySelected') === "Sevilla" ? -5.984927 : -3.702205;
    }),
    clear() {
      this.set('resMessage', null);
      this.set('errMessage', null);

      this.set('citySelected', "Sevilla");
    },
    actions: {
      changeItems(item) {
        this.set('citySelected', item);
        this.set('resMessage', null);
      },
      submit(form) {
        let self = this;
        this.set('resMessage', null);

        this.set("application.textLog", "COMPROBAR GEOCERCAS");
        let longitud = form.get('longitud');
        let latitud = form.get('latitud');

        this.get('application').req('GET', `col/geocercas?q={"loc":{"$geoIntersects":{"$geometry":{"type": "Point","coordinates":[ ${longitud}, ${latitud} ]}}}}`, {}).then(resp => {
          if (resp.errors && resp.errors[0].status == "422" && resp.errors[0].title && resp.errors[0].detail) {
            self.set('errMessage', `${resp.errors[0].title}:${resp.errors[0].detail}`);
          } else {
            let num = resp.meta.total;
            let where = num ? 'dentro' : 'fuera';
            self.set('resMessage', `${num} Geocercas encontradas.<br>Significa que el geopunto está <strong>${where}</strong> de la geocerca.`);
            self.set('errMessage', null);
          }
        });
      }
    }
  });
});