define('a2s-test/components/show-log/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    init() {
      this._super(...arguments);
      this.set("application.textLog", "");
    }
  });
});