define('a2s-test/social/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    logData: "",
    model: "",
    hasFacebook: false,
    userId: "",
    accessToken: "",
    pageToken: "",
    torii: Ember.inject.service('torii'),
    messages: Ember.A(),
    clear() {
      this.get('messages').clear();
      this.set('twtMsg', "");
      this.set('twtLnk', "https://www.nationalgeographic.com.es/animales/elefantes");
      this.set('hasFacebook', false);
      this.set('accessToken', '');
      this.set('pageToken', '');
    },
    loginFb(auth) {
      console.log('FaceBook Auth: ' + auth.userId);
      console.log(auth.accessToken);
      this.get("application").addLog(`<br>AUTH a A2Server con Token de Facebook`);

      this.get('application').req('POST', 'col/users/login', {}, {
        "user": {
          "fb": {
            "id": auth.userId,
            "token": auth.accessToken
          }
        },
        "type": "fb"
      }).then(resp => {
        console.log(resp);
        if (resp.user) {
          this.get('messages').pushObject({ type: "success", msg: `${resp.user.name} Hizo Login en A2Server correctamente` });
        } else {
          this.get('messages').pushObject({ type: "warning", msg: `Problemas al hacer login` });
        }
      }).catch(err => {
        console.log(err);
        this.get('messages').pushObject({ type: "danger", msg: `Problema Inesperado` });
      });
    },
    actions: {
      onChange() {},
      twtPost(form) {
        this.get('messages').clear();
        this.set("application.textLog", "POST en Twitter");
        this.get('application').req('POST', 'srv/social/post?service=twitter', {}, {
          "token": "2558633478-4SjodcSfuZ8fiyDHlgsRe0k7vvZe2PgMnYFD0wj",
          "tokenSecret": "XWf6g367B2UG2aGEFWvRcwb7EZNUA5CoGAf9PP9p2k0Z3",
          "data": {
            "message": form.twtMsg,
            "link": form.twtLnk
          }
        }).then(resp => {
          if (resp.created_at) {
            this.get('messages').pushObject({ type: "success", msg: `Publicacion creada: ${resp.text}
          <br><br>Ver al usuario <a target="_blank" href="https://twitter.com/wesingyou">@wesingyou en Twitter.</a>` });
          } else {
            this.get('messages').pushObject({ type: "warning", msg: `¡Problemas al publicar! ${resp.message}` });
          }
        }).catch(() => {
          this.get('messages').pushObject({ type: "danger", msg: `¡Error inesperado del servidor!` });
        });
      },
      signInToComment() {

        //var controller = this.controllerFor('post');
        // The provider name is passed to `open`
        this.get("application").setLog("AUTH Facebook");
        this.get('torii').open('facebook-connect').then(authorization => {
          this.get("application").addLog(`<br><br><strong><-RX:</strong>${JSON.stringify(authorization, null, ' ')}`);

          if (authorization.accessToken) {
            this.set('accessToken', authorization.accessToken);
            this.set('userId', authorization.userId);
            this.set('hasFacebook', true);
            this.get('messages').pushObject({ type: "success", msg: `Autenticado correctamente con Facebook` });

            this.get("application").addLog("GET Facebook Page Token");

            this.get('application').req('GET', `srv/social/pagetoken?service=facebook&page_id=311119459484572&access_token=${authorization.accessToken}`, {}).then(resp => {
              if (resp.access_token) {
                this.set('pageToken', resp.access_token);
              } else {
                this.get('messages').pushObject({ type: "warning", msg: `Problemas al obtener autorización para publicar en la página` });
              }
            }).catch(err => {
              this.get('messages').pushObject({ type: "danger", msg: `¡Problemas al hacer Login! ${err}` });
            });
          } else {
            this.get('messages').pushObject({ type: "warning", msg: `NO SE HA Autenticado correctamente con Facebook` });
          }
        }).catch(err => {
          this.get('messages').pushObject({ type: "danger", msg: `¡Problemas al hacer Login! ${err}` });
          console.log("mal", err);
        });
        //this.send('signInToCommentRoute');
      },
      fbPost(form) {
        this.get('messages').clear();
        this.set("application.textLog", "POST en Facebook");
        this.get('application').req('POST', 'srv/social/post?service=facebook', {}, {
          "token": this.get('pageToken'),

          "data": {
            "message": form.twtMsg,
            "link": form.twtLnk
          }
        }).then(resp => {
          if (resp.id) {
            this.get('messages').pushObject({ type: "success", msg: `Publicacion creada!
          <br><br>Entra de nuevo en <a target="_blank" href="https://www.facebook.com/ToroBack-Testing-Page-311119459484572">Pagina de prueba Toroback en Facebook</a> para actualizarla y ver la Publicación.` });
          } else {
            this.get('messages').pushObject({ type: "warning", msg: `¡Problemas al publicar! ${resp.message}` });
          }
        }).catch(() => {
          this.get('messages').pushObject({ type: "danger", msg: `¡Error inesperado del servidor!` });
        });
      }
    }
  });
});