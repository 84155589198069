define('a2s-test/geolocalizacion/radio/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    latitud: 0,
    longitud: 0,
    radio: 0,

    clear() {
      this.set('resMessage', null);
      this.set('errMessage', null);

      this.set('latitud', 36.713424);
      this.set('longitud', -4.4607517);
      this.set('radio', 3);
      this.set('gasolineras', []);
    },
    actions: {
      submit(form) {
        let self = this;
        this.set("application.textLog", "BUSCAR POR RADIO");
        this.set('gasolineras', []);
        let radio = form.radio / 6378.1;
        this.get('application').req('GET', `col/gasolineras?limit=100&fields=loc,workingHours,name,localId,currency,prices&q={"loc":{"$geoWithin":{"$centerSphere":[[${form.longitud},${form.latitud}],${radio}]}}}`, {}).then(resp => {
          if (resp.errors && resp.errors[0].status == "422" && resp.errors[0].title && resp.errors[0].detail) {
            self.set('errMessage', `${resp.errors[0].title}:${resp.errors[0].detail}`);
          } else {
            let num = resp.gasolineras.length;
            self.set('resMessage', `${num} Gasolineras Encontradas`);
            self.set('gasolineras', resp.gasolineras);
            self.set('errMessage', null);
          }
        });
      }
    }
  });
});