define("a2s-test/usuarios/correo/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    resMessage: "",
    errMessage: "",
    resMessage1: "",
    errMessage1: "",
    clear() {
      this.set('resMessage', null);
      this.set('errMessage', null);
      this.set('resMessage1', null);
      this.set('errMessage1', null);

      this.set('email', "");
      this.set('clave', "");
    },
    actions: {
      submit(form) {
        let self = this;
        this.set("application.textLog", "Crear cuenta con Correo");
        this.get('application').req('POST', 'col/users', {}, {
          "user": {
            "email": {
              "login": form.email,
              "password": form.clave
            }
          }
        }).then(resp => {
          if (!resp.errors) {
            self.set('resMessage', `Registro ${resp.users[0].name} creado`);
            self.set('errMessage', null);
          } else {
            self.set('resMessage', null);
            let msg = resp.errors[0].detail;
            if (msg && msg.startsWith('E11000')) {
              msg = '<strong> - Este usuario ya existe</strong><br>' + msg;
            }
            this.set('errMessage', msg);
          }
        }).catch(err => {
          self.set('resMessage', null);
          self.set('errMessage', "Error Inesperado");
        });
      },
      login(form) {
        let self = this;
        this.set("application.textLog", "Login con cuenta con Correo");
        this.get('application').req('POST', 'col/users/login', {}, {
          "user": {
            "email": {
              "login": form.email,
              "password": form.clave
            }
          },
          "type": "mail"
        }).then(resp => {
          if (resp.user) {
            self.set('resMessage1', `Login correcto, accessToken ${resp.accessToken} `);
            self.set('errMessage1', null);
          } else {
            self.set('resMessage1', null);
            this.set('errMessage1', resp.message);
          }
        }).catch(err => {
          self.set('resMessage1', null);
          self.set('errMessage1', "Error Inesperado");
        });
      }
    }
  });
});