define('a2s-test/application/service', ['exports', 'a2s-test/config/environment', 'fetch'], function (exports, _environment, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    textLog: "",
    addLog(text) {
      this.set("textLog", this.get("textLog").concat(text));
    },
    setLog(text) {
      this.set("textLog", text);
    },
    req(method, sufUrl, options, body) {
      let url = `${_environment.default.srv}${sufUrl}`;
      let app = this;
      let bb = body ? JSON.stringify(body, null, " ") : "";
      let logData = this.get('textLog') + `<br><br><strong>->TX:</strong> ${method} ${url} <br>${bb}`;
      this.set('textLog', logData);
      return new Promise(function (resolve, reject) {
        options = {
          credentials: 'include',
          method: method,
          headers: {
            'Content-Type': 'application/json'
          }
        };
        if (method != "GET") options.body = JSON.stringify(body);

        (0, _fetch.default)(url, options).then(response => {
          response.json().then(data => {
            let logData = app.get('textLog') + `<br><br><strong><-RX:</strong> ${JSON.stringify(data, null, ' ')}`;
            app.set('textLog', logData);
            resolve(data);
          }).catch(err => {
            reject(err);
          });
        }).catch(err => {
          // this.set('errMessage', "Problemas con el servidor intente más tarde o comuníquese con el administrador");
          // this.set('error', true);
          this.set('logData', "error");
          reject(err);
        });
      });
    },
    upload(sufUrl, data) {
      let url = `${_environment.default.srv}${sufUrl}`;
      let app = this;
      let logData = this.get('textLog') + `<br>-->POST ${url}`;
      this.set('textLog', logData);
      return new Promise(function (resolve, reject) {
        (0, _fetch.default)(url, {
          credentials: 'include',
          method: 'POST',
          body: data
        }).then(response => {
          response.json().then(resp => {
            let logData = app.get('textLog') + `<br><--${JSON.stringify(resp)}`;
            app.set('textLog', logData);
            resolve(resp);
          });
        }).catch(err => {
          // this.set('errMessage', "Problemas con el servidor intente más tarde o comuníquese con el administrador");
          // this.set('error', true);
          this.set('logData', "error");
          reject(err);
        });
      });
    }
  });
});