define('a2s-test/almacenamiento/hook-post/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      delete(row) {
        this.set("application.textLog", "DELETE vehiculos");
        this.get('application').req('DELETE', `col/vehiculos/${row._id}`, {}, {});
        let model = this.get('model.vehiculos');
        model.removeObject(row);
      }
    }
  });
});