define('a2s-test/almacenamiento-archivos/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    model: "",
    messages: Ember.A(),
    msgModal: "",

    init() {
      this._super(...arguments);
    },
    clear() {
      this.get('messages').clear();
      this.clearModal();
    },
    clearModal() {
      this.set('openModal', false);
      this.set('msgModal', "");
    },
    showModal(msg) {
      this.set('openModal', true);
      this.set('msgModal', msg);
    },
    getFiles() {
      this.set("application.textLog", "OBTENER Archivos");
      this.get('application').req('GET', 'srv/storage?service=gcloud&container=a2server-pruebas', {}).then(resp => {
        this.set('model', resp.files);
      });
    },
    actions: {
      delete(ele) {
        this.get('messages').clear();
        this.set("application.textLog", "ELIMINAR Archivo");
        this.get('application').req('DELETE', `srv/storage?service=gcloud&container=a2server-pruebas&path=${ele.path}`, {}, {});
        let model = this.get('model');
        this.get('messages').pushObject({ type: "success", msg: `Archivo ${ele.path} eliminado correctamente` });
        model.removeObject(ele);
      },
      upload(ele) {
        //validar si no existen archivos
        //validar si el tamaño es muy grande
        let self = this;
        this.get('messages').clear();
        let input = document.querySelector('input[type="file"]');
        let file = input.files[0] || null;
        if (!file) {
          this.showModal("Debe seleccionar un archivo primero");
        } else if (file.size > 5 * 1024 * 1024) {
          this.showModal("¡El Archivo es muy grande! <br> Debe ser menor a 6Mb");
        } else {
          this.set("application.textLog", "SUBIR Archivo");

          var data = new FormData();
          data.append('fileUpload', input.files[0]);
          data.append('path', `app-demo/files/${input.files[0].name}`);
          console.log('Tmaño', input.files[0].size);

          this.get('application').upload('srv/storage/upload?service=gcloud&container=a2server-pruebas&public=true', data).then(resp => {
            if (resp.file.path) {
              this.get('messages').pushObject({ type: "success", msg: `Archivo ${resp.file.path} subido` });
              self.getFiles();
            } else {
              this.get('messages').pushObject({ type: "danger", msg: "Error: Archivo no subido" });
            }
            //
          });
        }
      }
    }
  });
});