define('a2s-test/almacenamiento/validacion-fail/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    modelo: "SEAT León",
    //matricula: "1234TGV",
    matricula: Ember.computed('', function () {
      return Math.floor(Math.random() * 100000 + 1) + "AS";
    }),
    year: "3025",
    notificar: "maga.napanga@gmail.com",
    clear() {
      this.set('resMessage', null);
      this.set('errMessage', null);
      this.set('matricula', Math.floor(Math.random() * 100000 + 1) + "AS");
      this.set('year', "3025");
    },
    actions: {
      submit(form) {
        let self = this;
        this.set("application.textLog", "Crear documento vehiculo");
        let matricula = form.get('matricula');
        this.get('application').req('POST', 'col/vehiculos', {}, {
          "vehiculo": {
            "modelo": form.modelo,
            "matricula": matricula,
            "year": form.year,
            "notificar": form.notificar
          }
        }).then(resp => {
          if (resp.errors && resp.errors[0].status == "422" && resp.errors[0].title && resp.errors[0].detail) {
            self.set('errMessage', `${resp.errors[0].title}:${resp.errors[0].detail}`);
          } else {
            self.set('errMessage', null);
          }
        });
      }
    }
  });
});